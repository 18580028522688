<template>
    <div class="order">
        <el-table ref="mainTable" :data="dataList" style="width: 100%" stripe border>
            <el-table-column prop="avatar" label="图标" width="160" align="center">
                <template slot-scope="scope">
                    <el-image style="width:130px;height:130px" :src="scope.row.avatar | getImageUrl(CDN)" fit="contain"></el-image>
                </template>
            </el-table-column>
            <el-table-column prop="fullName" label="名称"></el-table-column>
            <!-- <el-table-column prop="introduce" label="简介" header-align="center"></el-table-column> -->
            <el-table-column prop="itemCount" label="商品数" width="100" align="center"></el-table-column>
            <el-table-column prop="sellCount" label="在售数" width="100" align="center"></el-table-column>
            <el-table-column prop="soldCount" label="售出数" width="100" align="center"></el-table-column>
            <el-table-column fixed="right" label="操作" width="240" align="center">
                <template slot-scope="scope">
                    <el-button @click="handleClick('view', scope.row)" type="text" size="small">产品列表</el-button>
                    <el-button @click="handleClick('edit', scope.row)" type="text" size="small">编辑</el-button>
                    <el-button @click="handleClick('delete', scope.row)" type="text" size="small">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination v-if="totalCount > pageSize" class="pt" background layout="prev, pager, next" @current-change="changePage" :page-size="pageSize" :total="totalCount"> </el-pagination>
    </div>
</template>
<script>
export default {
    data() {
        return {
            pageNo: 1,
            pageSize: 10,
            totalCount: 0,
            dataList: [],
            CDN: this.$cdn(),
        };
    },
    filters: {
        getImageUrl: function(val, cdn) {
            return cdn + val;
        },
    },
    mounted() {
        this.loadDataList();
    },
    methods: {
        formatter(row, column, value) {
            return value;
        },
        loadDataList: function() {
            this.$http({
                url: "/sys/art/artistList",
                data: {
                    pageNo: this.pageNo,
                    pageSize: this.pageSize,
                },
            }).then((res) => {
                this.totalCount = parseInt(res.data.total);
                this.dataList = res.data.records;
            });
        },
        handleClick: function(action, row) {
            // 删除
            if ("delete" == action) {
                this.$confirm("删除当前作者，将一并删除他的所有作品，确定要删除吗？", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        this.$http({
                            url: "/sys/art/deleteArtist",
                            data: {
                                id: row.id,
                            },
                        }).then((res) => {
                            this.loadDataList();
                        });
                    })
                    .catch(() => {});
            }
            // 查看
            if ("view" == action) {
                this.$router.push("/item/list?artistId=" + row.id);
            }
            // 编辑
            if ("edit" == action) {
                this.$router.push("/artist/create?artistId=" + row.id);
            }
        },
        // 翻页
        changePage: function(value) {
            this.pageNo = value;
            this.loadDataList();
        },
    },
};
</script>
<style></style>
